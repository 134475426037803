import React from "react"
import Layout from "../../components/layout"

import { FormProvider } from 'react-advanced-form'
import rules from '../../components/validation/validation-rules'
import messages from '../../components/validation/validation-messages'
import RegFormFields from "../../components/reg-form"

const RegForm = () => (
	<Layout>
		<div id="list-check-container" className="layered-gradient-gray">
			<div className="padding-all medium-padding-all-large">
				<div className="grid-x align-center">
					<div id="list-check" className="small-12 medium-10 large-7 cell text-center background-white">
						<div className="margin-bottom list-upload-form text-left large-padding-horizontal-large">
							<FormProvider rules={rules} messages={messages}>
								<RegFormFields />
							</FormProvider>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Layout>
)

export default RegForm
