import {Link} from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import logo from "../images/Free-List-Check-Logo---161x52.png"

class Header extends React.Component {
    render() {

        let spacerStyle = { width: '132px', minWidth: '132px;' };

    return (
      <header className="site-header">
        <div className="grid-container grid-x align-center-middle">
          <div className="cell shrink logo-container">
            <Link to="/">
              <img src={logo} alt="Free List Check by FreshAddress" className="logo"/>
            </Link>
          </div>
          <div className="cell shrink navigation-container">
            <ul className="navigation menu">
              <li className="menu-item">
                <Link to="/#howworks">
                  How It Works
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/Home/FAQ">
                  FAQ
                </Link>
              </li>
              <li className="menu-item">
                <a href="https://www.atdata.com/about" target="_blank" rel="noopener noreferrer">
                  About AtData
                </a>
                        </li>
                        {(typeof this.props.showScanMyList == 'undefined' || this.props.showScanMyList === true) ?
                            <li className="menu-button">
                                <Link to="/Home/Start" className="button">
                                    Check My List
                                </Link>
                            </li>
                            :
                            <span style={spacerStyle}>&nbsp;</span>
                        }
              
            </ul>
            <a className="off-canvas-toggle menu-icon" href="#" onClick={this.props.toggleFlyout}>
	            <span>Menu</span>
            </a>
          </div>
        </div>
      </header>
    )
  }
}

Header.propTypes = {
	siteTitle: PropTypes.string
}

Header.defaultProps = {
	siteTitle: ``,
}

export default Header
