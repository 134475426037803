/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useSiteMetadata } from "./use-site-metadata"
import { Link } from "gatsby"

import Header from "./header"
import MobileMenu from "./mobile-menu"
import "./layout.scss"

class Layout extends React.Component {

	constructor(props) {
		super(props);

		this.toggleFlyout = this.toggleFlyout.bind(this);

		this.state = {
			showFlyout: false,
		}
	}

	// Toggle the state of the flyout
	toggleFlyout() {
		this.setState(prevState => ({
		  showFlyout: ! prevState.showFlyout
		}));
	}

	render() {
		return (
			<div className={ this.state.showFlyout === true ? 'menu-open' : '' }>
				<MobileMenu toggleFlyout={this.toggleFlyout} />
				<div className="off-canvas-content">
					<Header siteTitle={<useSiteMetadata/>} toggleFlyout={this.toggleFlyout} showScanMyList={this.props.isScanButtonVisible} />
					<div>
						<main>{this.props.children}</main>
					</div>
					<footer className="site-footer">
						<div className="grid-container">
							<div className="grid-x">
								<div className="cell small-12 large-auto">
									<div className="footer-nav navigation-container">
										<ul className="navigation menu">
											<li className="menu-item">
												<Link to="/Home/FAQ">
													FAQ
												</Link>
											</li>
											<li className="menu-item">
												<a href="https://www.atdata.com/about" target="_blank" rel="noopener noreferrer">
													About AtData
												</a>
											</li>
											<li className="menu-item">
												<Link to="/Home/Terms">
													Terms of Service
												</Link>
											</li>
											<li className="menu-item">
												<a href="https://www.atdata.com/privacy-policy"
												   target="_blank" rel="noopener noreferrer">
													Privacy Policy
												</a>
											</li>
											<li className="menu-item">
												<a href="mailto:help@atdata.com">
													Support
												</a>
											</li>
										</ul>
									</div>
								</div>
								<div className="cell small-12 large-shrink">
									<div className="footer-social links-white text-center medium-text-right">
										<ul>
											<li><a href="https://www.instagram.com/atdataemailexperts/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}><span className="icon-instagram"></span></a></li>
											<li><a href="https://twitter.com/atdata" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}><span className="icon-twitter"></span></a></li>
											<li><a href="https://www.facebook.com/AtDataEmailExperts" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}><span className="icon-facebook"></span></a></li>
											<li><a href="http://www.linkedin.com/company/atdataemailexperts" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}><span className="icon-linkedin"></span></a></li>
										</ul>
									</div>
								</div>
							</div>
							<div className="grid-x">
								<div className="small-12 cell copyright">
									© 1999 - {new Date().getFullYear()}, AtData - All rights reserved
								</div>
							</div>
						</div>
					</footer>
				</div>
			</div>
		);
	}
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
}

export default Layout

