import React from 'react';

import { Form, Field } from 'react-advanced-form'
import Input from "./fields/Input"
import Select from "./fields/Select"
import Radio from "./fields/Radio"

class RegFormFields extends React.Component {
	render() {
		return (
			<div>
				<Form>
					<Field.Group name="primaryInfo">
						<Input name="first" type="text" label="First name" required />

						<Input name="last" type="text" label="Last name" required />

						<Input name="email" type="email" label="E-mail" required />

						<Input name="company" type="text" label="Company" required />

						<Input name="title" type="text" label="Title" required />

						<Input name="phone" type="text" label="Phone" />

						<Select name="HeardFrom" label="How did you hear about us?" required>
							<option>Select an Option</option>
							<option value="Current/Former Client">Current/Former Client</option>
							<option value="Facebook">Facebook</option>
							<option value="FreshAddress Blog">FreshAddress Blog</option>
							<option value="FreshAddress Newsletter">FreshAddress Newsletter</option>
							<option value="Google Ads">Google Ads</option>
							<option value="Google Search">Google Search</option>
							<option value="Instagram">Instagram</option>
							<option value="LinkedIn">LinkedIn</option>
							<option value="News Article">News Article</option>
							<option value="OnlyInfluencers Blog">OnlyInfluencers Blog</option>
							<option value="Press Release">Press Release</option>
							<option value="Referral, Word of Mouth">Referral, Word of Mouth</option>
							<option value="Referral, ESP">Referral, ESP</option>
							<option value="Twitter">Twitter</option>
							<option value="Tradeshow">Tradeshow</option>
							<option value="Webinar">Webinar</option>
							<option value="Other">Other</option>
						</Select>

						<Select name="listsize" label="What's your database size?">
							<option>Select an Option</option>
							<option value="&lt; 100k">&lt; 100k</option>
							<option value="100-500k">100-500k</option>
							<option value="500k-1mm">500k-1mm</option>
							<option value="1mm-5mm">1mm-5mm</option>
							<option value="&gt; 5mm">&gt; 5mm</option>
						</Select>

						<Input name="comment" type="text" label="Comments" />

						<strong>Stay in the know</strong>
						<Radio name="mktg" value="All" label="Yes, please! Sign me up for the monthly FreshPerspectives newsletter, product announcements, webinar invitations, and current promotions." />
						<Radio name="mktg" value="No" label="No thank you. Not right now." />
					</Field.Group>

					<button type="submit">Process My List</button>
				</Form>
			</div>
		);
	}
}

export default RegFormFields;
