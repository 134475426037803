import PropTypes from "prop-types"
import React from "react"
import {Link} from "gatsby";

class MobileMenu extends React.Component {

  render() {

    return (
      <div className="off-canvas-container">
	      <div className="off-canvas-overlay" onClick={this.props.toggleFlyout} role="menu"></div>
	      <div className="off-canvas off-canvas-menu-container">
		      <ul className="navigation menu">
			      <li className="menu-item">
				      <Link to="/#howworks">
					      How It Works
				      </Link>
			      </li>
			      <li className="menu-item">
				      <Link to="/Home/FAQ">
					      FAQ
				      </Link>
			      </li>
			      <li className="menu-item">
						<a href="https://www.atdata.com/about" target="_blank" rel="noopener noreferrer">
					      About AtData
				      </a>
			      </li>
			      <li className="menu-button">
				      <Link to="/Home/Start">
					      Check My List
				      </Link>
			      </li>
		      </ul>
	      </div>
      </div>
    )
  }
}

MobileMenu.propTypes = {
	siteTitle: PropTypes.string
}

MobileMenu.defaultProps = {
	siteTitle: ``,
}

export default MobileMenu
